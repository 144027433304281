.setup-card {
  position: relative;
  top: 200px;
}
.setup-page {
  // background-image: url("../../background.png");
}

.image-container {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-size: contain;
  .fg {
    position: absolute;
    background-color: rgba(231, 229, 229, 0.9);
    top: 50%;
    left: 45%;
    z-index: 9999;
    h1 {
      font-size: 2.75rem;
      color: red;
    }
  }
}
.signage-content {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.background {
  width: 100%;
  height: 100%;
  right: 0%;
  top: 0%;
  z-index: 1;
  position: absolute;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.custom-loader {
  position: absolute;
  top: 36%;
  h4 {
    padding: 10px;
    text-align: center;
  }
  .spinner-border {
    position: absolute;
    left: 42%;
  }
}

.menutrans {
  transform: translate3d(-103px, 26px, 0px);
}

.form-control:focus {
  border-color: $theme-color-primary;
}

.btn-primary,
.btn-secondary {
  color: #ffffff;
}
.file-drop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  p {
    margin-top: revert;
  }
}
// .invalid {
//   .select__control {
//     border-color: red;
//   }
// }
.app-card {
  &.selected-file {
    border: solid 4px;
    border-color: green;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.folder {
  cursor: pointer;
}
