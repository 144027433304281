.input-range__track {
  height: 0.7rem;
}

.input-range__slider {
  margin-top: -1.1rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: $theme-color-primary;
  border-color: $theme-color-primary;
}
.input-range__track--active {
  background-color: $theme-color-primary;
}
.value-info {
  display: flex;
  justify-content: space-between;
  // font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
}
