.react-tel-input {
  .form-control {
    width: 100%;
    padding: 0.6rem 0.375rem 0.6rem 3.75rem;
    min-height: 2.5rem;
    &:focus {
      border-color: $theme-color-primary;
      box-shadow: none;
    }
  }
}

.image-chooser {
  cursor: pointer;
  &:hover {
    background: white;
  }
}

.rc-time-picker {
  padding: 0 0;
}

.rc-time-picker-input {
  min-height: 2.5rem;
  border: none;
  border-radius: none;
  &:focus {
    border: 1px solid;
    border-color: $theme-color-primary;
  }
  &:focus-visible {
    outline: none;
  }
}

.rc-time-picker-clear,
.rc-time-picker-clear-icon:after {
  font-size: 1rem;
}

.rc-time-picker-panel-select,
.rc-time-picker-input,
.rc-time-picker-panel-input {
  font-size: 1rem;
}
