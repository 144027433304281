.app-login {
  .auth-background-holder {
    // background: url("../../images/background/bg_main.png") repeat center
    //   center;
    // @include background-size(cover);
    height: 100vh;
    min-height: 100%;
  }
}

.app-signup {
  .auth-background-holder {
    background: url("../../images/background/background-2.jpg") no-repeat center
      center;
    @include background-size(cover);
    height: 100vh;
    min-height: 100%;
  }
}

.app-reset-password {
  .auth-background-holder {
    background: url("../../images/background/background-3.jpg") no-repeat center
      center;
    @include background-size(cover);
    height: 100vh;
    min-height: 100%;
  }
}

.auth-main-col,
.auth-background-col {
  position: relative;
}

.auth-background-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: $theme-color-primary;
}

.auth-background-overlay {
  position: absolute;
  opacity: 0.4;
  // background: url("../../images/background/bg_main_light.png") repeat center
  // center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;

  .overlay-title {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }

  .overlay-content {
    background: rgba($theme-text-color-primary, 0.6);
    color: #fff;
    a {
      text-decoration: underline;
      color: #fff;
    }
  }
}

.app-auth-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.app-auth-wrapper {
  background: white;
  height: 100vh;

  .app-auth-body {
    width: 100%;
  }

  .app-logo {
    .logo-icon {
      width: 150px;
    }
  }
  .auth-heading {
    font-size: 1.5rem;
  }

  .form-control {
    @include placeholder($theme-text-color-light);
  }

  .legal-note {
    font-size: 1.2rem;
  }

  .extra {
    font-size: 0.75rem;
    a {
      text-decoration: underline;
      color: $theme-text-color-light;
      &:hover {
        color: $theme-text-color-secondary;
      }
    }
  }

  .auth-heading-desc {
    font-weight: 500;
    font-size: 1.125rem;
  }

  .legal-note {
    font-size: 0.75rem;
  }

  .auth-option {
    font-size: 1.2rem;
  }
}

.app-auth-footer {
  .copyright {
    font-size: 0.75rem;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .auth-background-col {
    display: none;
  }

  .app-auth-wrapper .app-auth-body {
    width: 100%;
  }
}
