.device-monitor {
  .item {
    width: 100%;
    .item-thumb-holder {
      background: #e9eaf1;
      text-align: center;
      position: relative;
      height: 216px;
      .item-thumb {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: black;
        .thumb-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
